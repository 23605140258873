@use 'colors' as *;
@use 'typography' as *;

:root {
    /**
    @font family declaration
    */
    @each $ff, $shades in $font-family {
        @each $shade, $value in $shades {
            --it-#{$ff}-#{$shade}: #{$value};
        }
    }

    /**
    @color declaration
    */
    @each $color, $shades in $colors {
        @each $shade, $value in $shades {
            --it-#{$color}-#{$shade}: #{$value};
        }
    }

}
