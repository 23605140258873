@use '../utils' as *;
/*-----------------------------------------------------------------------------------

    Theme Name: Template Name – HTML5 Template
    Author: Theme Pure
    Support:ordianit@gmail.com
    Description: Template Name  – HTML5 Template
    Version: 1.0

-----------------------------------------------------------------------------------

/************ TABLE OF CONTENTS ***************

    01. THEME DEFAULT CSS
	02. MEANMENU CSS START
	03. header CSS START
	04. hero CSS START
	05. about CSS START
	06. feature CSS START
	07. service CSS START
	08. app CSS START
	09. faq CSS START
	10. choose CSS START
	11. counter CSS START
	12. brand CSS START
	13. cta CSS START
	14. testimonial CSS START
	15. support CSS START
	16. platform CSS START
	17. price CSS START
	18. project CSS START
	19. team CSS START
	20. blog CSS START
	21. contact CSS START
	22. 404 CSS START
	23. footer CSS START

**********************************************/

/*----------------------------------------*/
/* 01. THEME DEFAULT CSS START
/*----------------------------------------*/

//@import url($font-url);
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&family=Rubik:wght@300;400;500;600;700;800;900&display=swap');

// font family
@media (min-width: 1400px){
    .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
        max-width: 1200px;
    }
}
.container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
	--bs-gutter-x: 30px;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/*---------------------------------
	typography css start 
---------------------------------*/
body {
    font-family: var(--it-ff-body);
    font-size: 15px;
    font-weight: 400;
    color: var(--it-text-body);
    line-height: 1.3;
    overflow-x: hidden;
}
body.monserat {
    font-family: var(--it-ff-monserat);
}

a {
    text-decoration: none;
    transition: .3s;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: var(--it-ff-heading);
    color: var(--it-heading-primary);
    margin-top: 0px;
    font-weight: 700;
    line-height: 1.1;
    @include transition(.3s);
}

img {
    max-width: 100%;
}

h1 {
    font-size: 40px;
}

h2 {
    font-size: 32px;
}

h3 {
    font-size: 28px;
}

h4 {
    font-size: 24px;
}

h5 {
    font-size: 20px;
}

h6 {
    font-size: 16px;
}

ul {
    margin: 0px;
    padding: 0px;
}

p {
    color: var(--it-text-body);
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
}

.z-index {
    position: relative;
    z-index: 2;
}
.z-index-3 {
    position: relative;
    z-index: 3;
}
.z-index-4 {
    position: relative;
    z-index: 4;
}
.z-index-5 {
    position: relative;
    z-index: 5;
}
.z-index-6 {
    position: relative;
    z-index: 6;
}

a,
.btn,
button,
input,
select,
textarea,
li,
img,
.transition-3,
h1,
h2,
h3,
h4,
h5,
h6 {
    @include transition(.3s);
}

a:focus,
.button:focus {
    text-decoration: none;
    outline: none;
}

a:focus,
a:hover {
    color: inherit;
    text-decoration: none;
}

a,
button {
    color: inherit;
    outline: none;
    border: none;
    background: transparent;
}

button:hover {
    cursor: pointer;
}

button:focus {
    outline: 0;
}

.uppercase {
    text-transform: uppercase;
}

.capitalize {
    text-transform: capitalize;
}

input,
textarea {
    outline: none;
    color: var(--it-common-black);
    @include it-placeholder{
        color: #70737D;       
    }
}

input[type="color"] {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    background: none;
    border: 0;
    cursor: pointer;
    height: 100%;
    width: 100%;
    padding: 0;
    border-radius: 50%;
}

*::-moz-selection {
    background: var(--it-theme-1);
    color: var(--it-common-white);
    text-shadow: none;
}

::-moz-selection {
    background: var(--it-theme-1);
    color: var(--it-common-white);
    text-shadow: none;
}

::selection {
    background: var(--it-theme-1);
    color: var(--it-common-white);
    text-shadow: none;
}


*::-moz-placeholder {
    color: var(--it-common-black);
    font-size: var(--it-fz-body);
    opacity: 1;
}

*::placeholder {
    color: var(--it-common-black);
    font-size: var(--it-fz-body);
    opacity: 1;
}

/*---------------------------------
    common classes css start 
---------------------------------*/

.w-img {
    & img {
        width: 100%;
    }
}

.m-img {
    & img {
        max-width: 100%;
    }
}

.fix {
    overflow: hidden
}

.clear {
    clear: both;
}

.f-left {
    float: left;
}

.f-right {
    float: right;
}

.overflow-y-visible {
    overflow-x: hidden;
    overflow-y: visible;
}

.p-relative {
    position: relative;
}

.p-absolute {
    position: absolute;
}

.include-bg {
    @include background();
}

.gx-10 {
    --bs-gutter-x: 10px;
}
.gx-30 {
    --bs-gutter-x: 30px;
}
.gx-40 {
    --bs-gutter-x: 40px;
}

.gx-50 {
    --bs-gutter-x: 12px;
}
.gx-60 {
    --bs-gutter-x: 65px;
}
.gx-20 {
    --bs-gutter-x: 20px;
}
.gx-25 {
    --bs-gutter-x: 25px;
}
.gx-60 {
    --bs-gutter-x: 60px;
    @media #{$lg}{
        --bs-gutter-x: 40px; 
    }
}

